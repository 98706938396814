<template>
  <div class="card-home" :style="'height: '+pageHeight+'px'">
    <div class="left-menu">
      <div class="_menu">
        <div class="_item" v-for="item of typeList" @click="onClickCardItem(item)"
             :class="{_current: currType === item.id}">
          <img :src="item.image_url"><span>{{ item.welfare_name }}</span>
        </div>
      </div>
    </div>
    <!--    <div class="btn-back" @click="$router.go(-1)"><img src="../status/img/btn_back.png" alt=""></div>-->

    <div class="card-wrapper" v-if="typeDetail">
      <div class="card-banner"><img :src="typeDetail.banner" alt=""></div>
      <div class="_divider">
        <van-divider :style="{ color: '#cbcbcb', borderColor: '#cbcbcb', padding: '0 16px', fontSize: '12px' }">共
          {{ typeDetail.list.length }} 项权益
        </van-divider>
      </div>
      <div class="card-content">
        <div class="_water_html">
          <div class="_html clearfix">
            <div class="_left clearfix">
              <div class="_water_item"
                   @click="onClickPageLink(subItem.jump_type!=''?subItem.jump_type!=null?subItem.jump_type:'/activity?id='+subItem.id:'/activity?id='+subItem.id)"
                   v-for="(subItem, subIndex) of typeDetail.list" v-if="subIndex%2 === 0"><img :src="subItem.flow_img">
              </div>
            </div>
            <div class="_right clearfix">
              <div class="_water_item"
                   @click="onClickPageLink(subItem.jump_type!=''?subItem.jump_type!=null?subItem.jump_type:'/activity?id='+subItem.id:'/activity?id='+subItem.id)"
                   v-for="(subItem, subIndex) of typeDetail.list" v-if="subIndex%2 !== 0"><img :src="subItem.flow_img">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="_bottom_fixed _bottom_show_all">
      <div class="_height"></div>
      <div class="_bottom_back">
        <span class="_back_home" @click="$router.push('/home?type=')"></span>
        <span class="_back_prev" @click="$router.go(-1)"></span>
      </div>
    </div>
    <div class="_right_bar_new_card" @click="onClickPageLink('https://wx.scbank.cn/ares-web-wechat/wechat/authinit/applyDebitCard.do')"  >
      <img src="http://pic.eaworks.cn/scbank/uploads/2023922/6294112.png" >
    </div>
  </div>

</template>

<script>
import page_links from "../mixin/page_links";
import wx from "weixin-js-sdk";

export default {
  mixins: [page_links],
  components: {},
  data() {
    return {
      pageHeight: 1200,
      currCardType: 0,
      typeList: [],
      currType: 0,
      typeDetail: null,
      flowList: []
    };
  },

  created() {
    console.log("-> [show_all.vue] created");
  },

  mounted() {
    console.info(this.q)
    console.log("-> [card.vue] mounted");
    this.pageHeight = window.innerHeight || 1200;
    this.currCardType = Number(this.$route.query.card) || 1;
    this.getCardDetailByType();
    this.getTypeList();
    this.getJsInfo();
  },

  methods: {
    async getJsInfo() {
      // 生成网页js签名
      let {data, code} = await this.$ajax.gateway("/api/admin_get_js_sign", {
        "body": {
          "url": location.href.split('#')[0]
        }
      });
      if (code === 200) {
        data.res["jsApiList"] = [
          'checkJsApi',
          'updateTimelineShareData',
          'updateAppMessageShareData',
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'hideAllNonBaseMenuItem',
          'showAllNonBaseMenuItem'
        ]; wx.config(data.res);
      }
      wx.ready(function () {
        wx.updateTimelineShareData({
          title: '四川银行-安逸生活 从此开启', // 分享标题
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 设置成功
          }
        });
        wx.onMenuShareTimeline({
          title: '四川银行-安逸生活 从此开启', // 分享标题
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 用户点击了分享后执行的回调函数
          }
        });
        wx.updateAppMessageShareData({
          title: '四川银行', // 分享标题
          desc: '安逸生活 从此开启', // 分享描述
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 设置成功
          }
        });
      });

      wx.error(function (res) {
        console.info("wx error", res);
      });
    },
    onClickItem(item) {
      if (item.jump_type == null || item.jump_type == "") {
        this.$router.push("/activity?id=" + item.id);
      }
    },
    async getTypeList() {
      // 获取指定卡片的项目
      let {data} = await this.$ajax.gateway("/api/api_get_welfare_by_card", {
        "METHOD": "get",
        "card_id": this.currCardType
      });
      this.typeList = [
        {
          id: 0,
          image_url: "/page/pic/card/sub/sub_11.png",
          welfare_name: "全部"
        },
        ...data
      ];
      //
      this.onClickCardItem({id: 0});
    },

    async getCardDetailByType() {
      // 获取指定卡片的项目
      let {data} = await this.$ajax.gateway("/api/api_get_welfare_by_card", {
        "METHOD": "get",
        "card_id": this.currType
      });
      //
      this.typeDetail = {
        banner: "/page/pic/card/banner_9.png",
        list: []
      };

    },
    async onClickCardItem(item) {
      this.currType = item.id;
      // 获取首页卡片列表
      let {error, data} = await this.$ajax.gateway("/api/api_get_flow_merchant", {
        ERROR_BACK: true,
        "METHOD": "get",
        "welfare_id": item.id,
        "card_id": this.currCardType
      });
      this.typeDetail.list = data;
      console.info(this.typeDetail.list);
    },
  }
};
</script>

<style lang="less" scoped>
.card-home {
  max-width: 750px;
  margin: 0 auto;
  position: relative;
  background: #ebebeb;

  .left-menu {
    width: 2.4rem;
    height: 100%;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    ._menu {
      padding-top: 0.6rem;

      ._item {
        padding: 0.28rem 0 0.2rem;
        font-size: 0.35rem;
        position: relative;
        margin-bottom: 0.2rem;
        text-align: left;
        text-indent: 0.25rem;

        img {
          display: inline-block;
          width: 0.6rem;
          height: 0.6rem;
          border-radius: 100%;
          border: 1px solid #eee;
          margin-left: -0.1rem;
        }

        span {
          position: relative;
          top: -0.2rem;
          padding-left: 0.1rem;
        }

        &._current {
          background: #ebebeb;
          color: #f86a4b;

          &:before, &:after {
            content: ' ';
            width: 0.4rem;
            height: 0.4rem;
            position: absolute;
            right: -1px;
          }

          &:before {
            top: -0.4rem;
            background: url("../status/img/left_menu_current_3.png") no-repeat right center;
            background-size: auto 100%;
          }

          &:after {
            bottom: -0.4rem;
            background: url("../status/img/left_menu_current_4.png") no-repeat right center;
            background-size: auto 100%;
          }
        }
      }
    }
  }

  .btn-back {
    position: fixed;
    bottom: 1rem;
    left: -5px;
    z-index: 11;

    img {
      display: block;
      width: 2.2rem;
    }
  }
}

.card-wrapper {
  padding: 0 0.2rem 0 2.6rem;
  min-height: 10rem;
  background: #ebebeb;

  .card-banner {
    img {
      display: block;
      width: 100%;
    }
  }

  ._water_html {
    ._html {
      ._left, ._right {
        width: 50%;
        float: left;

        ._water_item {
          float: left;
          margin-bottom: 0.2rem;

          img {
            display: block;
            width: 100%;
          }
        }
      }
    }
  }
  ._right_bar_new_card{
    position: fixed;
    top: 0.3rem;
    right: 0rem;
    img {
      height: 2.7rem;
    }
  }
}
</style>
